import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Select,
  Divider,
  Tooltip,
  Space,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  RollbackOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  monthNames,
} from '../../utils/dateUtils';

import { OTBTypesOptionArray, toProperCase } from '../../utils/generic';
import moment from 'moment';
import { forEach } from 'lodash';

const OTBDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [dataform] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const [fabricList, setfabricList] = useState([]);
  const [launchMastersList, setLaunchMastersList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [selectedLaunchNew, setSelectedLaunchNew] = useState(0);

  const [saving, setSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(null);

  const [productOptionDetails, setProductOptionDetails] = useState([]);

  const authToken = getToken();

  //   console.log(location, 'line no 69');
  //   return;

  // console.log(totalQuantityCount, 59);
  useEffect(() => {
    console.log(location, 'line no 40');
    if (location.state === null) {
    } else {
      setIsLoading(true);

      let productOptionAttributeArray = getProductOptionAttributeArray(
        location.state.productOptionDetails
      );

      console.log(productOptionAttributeArray, '74');
      setEditData({
        month: location.state?.month,
        launch_master: location.state.launchDetails.launchRefId,
        totalUnits: location.state.totalUnits,
        comments: location.state.comments,
        totalOptionQty: location.state.totalOptionQty,
        type: location.state.type,
        status: location.state.status,
        productOptionQties: productOptionAttributeArray,
        editId: location.state.id,
      });
      setProductOptionDetails(location.state.productOptionDetails);
      setIsLoading(false);
      location.state?.launchDetails?.launchRefId &&
        setSelectedLaunchNew(location.state.launchDetails.launchRefId);
    }
    fetchLaunchList(authToken);
    fetchProductTypeList(authToken);
  }, []); // setting the required data for the form based on edit / create request.

  useEffect(() => {
    console.log(editData);
    dataform.setFieldsValue(editData);
    if (dataform) {
      console.log(97, dataform);
      console.log(dataform.getFieldValue('productOptionQties'));
    }
  }, [editData, dataform]); // set form fields on change in editdata or dataform.

  useEffect(() => {
    if (selectedLaunchNew) {
      console.log(selectedLaunchNew, 'SelectedLaunchNew');
    }
  }, [selectedLaunchNew]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const getProductOptionAttributeArray = (productOptionDetailObjectArray) => {
    return productOptionDetailObjectArray.map((productOptionObject) => ({
      poqId: productOptionObject.poqId,
      quantity: productOptionObject.quantity,
      product_option_type: productOptionObject.poObject.productCatId,
    }));
  };
  const fetchFabricList = async (token) => {
    let fabricData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/fabrics?`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fabricData2 = responseData.data.map((entry) => {
          return {
            id: entry.id,
            fabricName: entry.attributes.fabricName,
            fabricQuality: entry.attributes.fabricQuality,
            fabricCode: entry.attributes.fabricCode,
          };
        });

        setfabricList(fabricData2);

        console.log('original fabric Data:', responseData);
        console.log('modified fabric Data:', fabricData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // drop down options for fabrics

  const fetchLaunchList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData?.data?.map((entry) => {
        return {
          id: entry?.id,
          launchName: entry?.attributes?.launchName,
          launchDate: entry?.attributes?.launchDate,
          launchDrop: entry?.attributes?.launchDrop,
          launchCat: entry?.attributes?.launchCat,
          launchMonth: entry?.attributes?.launchMonth,

          seasonName: entry?.attributes?.season?.data?.attributes?.season_id,
          // launch_masters: entry?.attributes?.launch_masters,
        };
      });

      setLaunchMastersList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductTypeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/product-option-types`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData?.data?.map((entry) => {
        return {
          id: entry?.id,
          productCategoryName: entry?.attributes?.productCategoryName,
        };
      });

      setProductTypeList(optionsData2);
      console.log('original Product Type Data:', optionsData);
      console.log('modified Product type Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const addNewRecord = async (data) => {
    setSaving(true);

    const values = {
      ...data,
      address: {
        addressDescription: data.addressDescription,
        country: data.country,
        state: data.state,
        street: data.street,
        pincode: data.pincode,
        city: data.city,
      },
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(`${API}/otb-records/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/otb-records/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  const launchListOptions = launchMastersList.map((launch) => {
    return {
      label: launch.launchName,
      value: launch.id,
    };
  });

  const ProductTypeOptions = productTypeList.map((productType) => {
    return {
      label: productType.productCategoryName,
      value: productType.id,
    };
  });

  // ==============
  const onFinish = async (data) => {
    console.log('Received values of form:', data);

    setSaving(true);
    if (!data?.productOptionQties?.length) {
      message.error('Product Options are required');
      setSaving(false);
      return;
    }

    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data?.data?.editId);

    if (data?.data?.editId) {
      console.log('edit id :', data?.data?.editId);

      try {
        const response = await fetch(
          `${API}/otb-records/${data?.data?.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data?.editId);

      try {
        const response = await fetch(`${API}/otb-records/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    console.log('Changed values:', changedValues);
    console.log('All values:', allValues);

    let totalOptionQtyCount = allValues?.productOptionQties?.reduce(
      (totalQty, currentOptionQty) => {
        console.log(currentOptionQty);

        return totalQty + parseInt(currentOptionQty?.quantity);
      },
      0
    );
    console.log('total', totalOptionQtyCount);
    let totalUnitsQty = totalOptionQtyCount * 100;
    if (totalOptionQtyCount) {
      dataform.setFieldsValue({
        totalOptionQty: totalOptionQtyCount,
        totalUnits: totalUnitsQty,
      });
    } else {
      dataform.setFieldsValue({ totalOptionQty: 0, totalUnits: 0 });
    }
  };

  const updateDependentField = (value) => {
    console.log('Updating', value, launchMastersList);
    const selectedLaunch = launchMastersList?.filter((launch) => {
      return launch?.id === value && launch;
    });

    console.log(selectedLaunch, 427);
    setEditData({
      ...editData,
      launch_master: selectedLaunch[0].id,
      month: selectedLaunch[0]?.launchMonth,
    });
  };

  const removeExtraField = (index) => {
    const updatedProductOptionDetails = [...productOptionDetails];
    updatedProductOptionDetails?.splice(index, 1);
    setProductOptionDetails(updatedProductOptionDetails);
  };

  const monthListOptions = monthNames.map((month, index) => {
    return {
      label: month,
      value: month,
    };
  });

  const OTBTypesOptionList = OTBTypesOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const autofillLaunchDetails = (value) => {
    console.log(value);
  };

  return (
    <div>
      <div>OTB Details</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form
          form={dataform}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={handleValuesChange}
          disabled={editData?.status === 'prod_option_generated'}
        >
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Launch Master"
                name="launch_master"
                rules={[
                  {
                    required: true,
                    message: 'Launch is Required!',
                  },
                ]}
              >
                <Select
                  showSearch
                  onChange={updateDependentField}
                  placeholder="Select "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={launchListOptions}
                />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Month"
                name="month"
                rules={[
                  {
                    required: true,
                    message: 'Month is Required!',
                  },
                ]}
              >
                {<Select options={monthListOptions} />}
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Total Units"
                name="totalUnits"
                rules={[
                  {
                    required: true,
                    message: 'Total Units is Required!',
                    type: 'decimal',
                  },
                ]}
              >
                <Input placeholder="Total Units" disabled={true} />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Status" name="status" initialValue="active">
                <Select placeholder="Select Status">
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="inactive">In Active</Select.Option>
                  <Select.Option value="wip">WIP</Select.Option>
                  <Select.Option value="droped">Droped</Select.Option>
                  <Select.Option value="confirmed">confirmed</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Total Option Quantity"
                name="totalOptionQty"
                rules={[
                  {
                    required: true,
                    message: 'Total Option Quantity is Required!',
                  },
                ]}
              >
                <Input
                  placeholder="Enter total Option Quantity"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Type is Required!',
                  },
                ]}
              >
                <Select
                  placeholder="Select "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={OTBTypesOptionList}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.List name="productOptionQties">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label="Product Option Type"
                      name={[name, 'product_option_type']}
                      rules={[
                        {
                          required: true,
                          message: 'Product Option Type is required',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select "
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={ProductTypeOptions}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Quantity"
                      name={[name, 'quantity']}
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: 'Quantity is required and Must be a number',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Quantity"
                        // onChange={updateTotalQuantityCount}
                      />
                    </Form.Item>
                    <Form.Item
                      label="poqId"
                      hidden={true}
                      name="poqId"
                      initialValue={0}
                    >
                      <Input type="text" />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                      }}
                    />
                  </Space>
                ))}

                <Form.Item label="Add Product Options">
                  <Tooltip title="Add Product Option">
                    <Button
                      onClick={() => add()}
                      shape="circle"
                      icon={<PlusCircleOutlined />}
                    />
                  </Tooltip>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item label="editId" hidden={true} name="editId">
            <Input type="text" />
          </Form.Item>
          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default OTBDetails;
