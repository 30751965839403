export const coreProductStatusArray = [
    { object_status_no: 1, object_status: "active" },
    { object_status_no: 2, object_status: "wip" },
    { object_status_no: 3, object_status: "completed" },
    { object_status_no: 4, object_status: "confirmed" },
    { object_status_no: 5, object_status: "fabric_complete", hide: true },
    { object_status_no: 6, object_status: "techpack_complete" },
    { object_status_no: 7, object_status: "bookable" },
    { object_status_no: 8, object_status: "booked" },
    { object_status_no: 9, object_status: "receiving_due", hide: true },
    { object_status_no: 10, object_status: "receiving_pending", hide: true },
    { object_status_no: 11, object_status: "received", hide: true },
    { object_status_no: 12, object_status: "postponed", hide: true },
    { object_status_no: 13, object_status: "canceled", hide: true },
    { object_status_no: 14, object_status: "dropped", hide: true },
    { object_status_no: 15, object_status: "deleted", hide: true },
]

export const attributionCategoryOptionArray = [
    "semi-casual",
    "casual",
    'formal'
]
export const paymentModelOptionArray = [
    "cmt",
    "fob",
]

export const fabricLiabilityStatusArray = [
    "with-fabric-liability",
    "without-fabric-liability",
    "others",
]

export const patternOptionArray = [
    "check",
    "dobby",
    'twill'
]
export const knitWovenOptionArray = [
    "Circular Knit",
    "Flat Knit",
    "Woven",
    "Circular Knit + Flat Knit",
]

export const weightOptionArray = [
    "low-weight",
    "mid-weight",
    "high-weight",
    "ultra-lightweight",
    "lightweight",
    "midweight",
    "heavyweight",
    "very-heavyweight",
]

export const colorDepthOptionArray = [
    "Brights",
    "Pastels",
    "Mid depths",
    "Darks",
]

export const freshnessOptionArray = [
    "New",
    "Injection",
    "Version",
    "Repeat",
    "Replenishment",
]

export const lifeOccasionOptionArray = [
    'Casual',
    'Evening',
    'Formal',
    'Occasion',
    'Semi Formal',
    'Semi Casual',
    'Evening-Occasion',
]


export const sleeveOptionArray = [
    "Half Sleeve",
    "Full Sleeve",
    "Sleeveless",
    "Full Break",
    "Half Break",
    "Ankle Length",
    "Thigh Length",
    "Above Knee Length",
    "Knee Length",
    "Below Knee Length",
    "Short Length Kurta",
    "Mid Length Kurta",
    "Long Length Kurta",
    "Full sleeve french cuff"
]

export const weaveStructureOptionArray = [
    "Twill",
    "Herringbone",
    "Jacquard",
    "Plain",
    "Satin",
    "Ikat",
    "Jamdani",
    "Pique",
    "Cable",
    "Single Jersey",
    "Intarsia",
    "Pointelle",
    "Crochet",
    "Basket",
    "Terry",
    "Crepe",
    "Waffle",
    "Interlock",
    "Extra warp / weft",
    "Oxford",
    "Mockleno",
    "Seersucker",
    "Dobby",
    "Fleece",
    "Cables",
]



export const tnaStatusOptionArray = [
    'active',
    'inactive',
    'wip',
    'pending',
    'droped',
    'postponed',
    'complete',
    'confirmed',
]

export const shipmentStatusOptionArray = [
    'full',
    'part',
]
export const fabricApplicationTypeArray = [
    'shell',
    'trim',
]

export const OTBTypesOptionArray = [
    'planning-buy',
    'design-buy',
    'repeat-replenishment',
    'design-injections',
]

export const designCategoryOptionArray = [
    "Fashion",
    "Core",
    "Indian",
    "Experimental",
]

export const fabricSpecialAttributeOptionArray = [
    "Délavé",
    "Brushed",
    "Non - iron / wrinke free",
    "Special yarn",
    "Textured",
    "Gold finish",
    "Super fine knit",
    "All over jacquard",
    "Placement jacquard",
    "Slub",
    "Neps",
    "Peach finish",
    "Mercerized",
    "NA",
]





export const toProperCase = (str) => {

    return str.replace(
        /\w*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};




export default coreProductStatusArray;